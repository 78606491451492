(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('DynamicForm', DynamicForm);

    DynamicForm.$inject = ['$resource'];

    function DynamicForm ($resource) {
        var resourceUrl =  'api/appraisal-report/:reportId/form/:formName';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return data ? angular.fromJson(data) : data;
                }
            },
            'getDefinitionFieldValues': {
                method: 'GET',
                url: 'api/appraisal-report/form/values',
                isArray: true,
                transformResponse: function (data) {
                    return data ? angular.fromJson(data) : data;
                }
            },
            'getTypeCategorizedFormDefinition': {
                method: 'GET',
                url: 'api/appraisal-report/form/:formName/types',
                transformResponse: function (data) {
                    return data ? angular.fromJson(data) : data;
                }
            },
            'save': {
                method: 'POST',
                headers: {'Content-Type': undefined },
                url: 'api/appraisal-report/:reportId/form/:formName'
            },
            'init': {method: 'POST', url: 'api/appraisal-report/:reportId/form/:formName/init'},
            'useThirdParty': { method: 'POST', url: 'api/appraisal-report/:reportId/form/use-third-party' }
        });
    }
})();
