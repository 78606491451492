(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('AppraisalState', AppraisalState);

    AppraisalState.$inject = ['$q', '$rootScope', 'Principal'];

    function AppraisalState ($q, $rootScope, Principal) {

        var _account;

        var service = {
            inState: inState,
            inAnyState: inAnyState,
            // States for Open Apprisal Orders dashboard
            open: [
                'PENDING_APPRAISER_ACCEPTANCE',
                'PENDING_REPORT_COMPLETION',
                'PENDING_ASSIGNMENT',
                'ORDER_DRAFTED',
                'PENDING_PAYMENT',
                'ACCEPTANCE_PENDING_CLARIFICATION',
                'ACCEPTANCE_PENDING_DUE_DATE_CHANGE',
                'ACCEPTANCE_PENDING_FEE_CHANGE',

                'REPORT_REJECTED',
                'REPORT_SUBMITTED', //Not currently used (reviewer workflow)
                'REPORT_COMPLETE',

                'PENDING_SCHEDULE_VISIT',
                'PENDING_VISIT',
                'PENDING_COSIGNER_ASSIGNMENT',
                'PENDING_COSIGN',
                'PENDING_SUBMISSION',
                'RESUBMISSION_REQUIRED',

                'PENDING_SCHEDULE_VISIT_CLARIFICATION',
                'PENDING_VISIT_CLARIFICATION',
                'PENDING_COSIGNER_ASSIGNMENT_CLARIFICATION',
                'PENDING_COSIGN_CLARIFICATION',
                'PENDING_SUBMISSION_CLARIFICATION',
                'PENDING_RESUBMISSION_REQUIRED_CLARIFICATION',

                'PENDING_SCHEDULE_VISIT_DUE_DATE_CHANGE',
                'PENDING_VISIT_DUE_DATE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_DUE_DATE_CHANGE',
                'PENDING_COSIGN_DUE_DATE_CHANGE',
                'PENDING_SUBMISSION_DUE_DATE_CHANGE',
                'PENDING_RESUBMISSION_REQUIRED_DUE_DATE_CHANGE',

                'PENDING_SCHEDULE_VISIT_FEE_CHANGE',
                'PENDING_VISIT_FEE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_FEE_CHANGE',
                'PENDING_COSIGN_FEE_CHANGE',
                'PENDING_SUBMISSION_FEE_CHANGE',
                'PENDING_RESUBMISSION_REQUIRED_DUE_DATE_CHANGE'
            ],
            /* Organized open order for ccr/admin */
            open_for_vcuser: [
                'PENDING_APPRAISER_ACCEPTANCE',
                'PENDING_REPORT_COMPLETION',
                'PENDING_ASSIGNMENT',
                'ACCEPTANCE_PENDING_CLARIFICATION',
                'ACCEPTANCE_PENDING_DUE_DATE_CHANGE',
                'ACCEPTANCE_PENDING_FEE_CHANGE',

                'REPORT_REJECTED',
                'REPORT_SUBMITTED', //Not currently used (reviewer workflow)
                'REPORT_COMPLETE',

                'PENDING_SCHEDULE_VISIT',
                'PENDING_VISIT',
                'PENDING_COSIGNER_ASSIGNMENT',
                'PENDING_COSIGN',
                'PENDING_SUBMISSION',
                'RESUBMISSION_REQUIRED',

                'PENDING_SCHEDULE_VISIT_CLARIFICATION',
                'PENDING_VISIT_CLARIFICATION',
                'PENDING_COSIGNER_ASSIGNMENT_CLARIFICATION',
                'PENDING_COSIGN_CLARIFICATION',
                'PENDING_SUBMISSION_CLARIFICATION',
                'PENDING_RESUBMISSION_REQUIRED_CLARIFICATION',

                'PENDING_SCHEDULE_VISIT_DUE_DATE_CHANGE',
                'PENDING_VISIT_DUE_DATE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_DUE_DATE_CHANGE',
                'PENDING_COSIGN_DUE_DATE_CHANGE',
                'PENDING_SUBMISSION_DUE_DATE_CHANGE',

                'PENDING_SCHEDULE_VISIT_FEE_CHANGE',
                'PENDING_VISIT_FEE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_FEE_CHANGE',
                'PENDING_COSIGN_FEE_CHANGE',
                'PENDING_SUBMISSION_FEE_CHANGE',
                'PENDING_RESUBMISSION_REQUIRED_DUE_DATE_CHANGE',
                'PENDING_RESUBMISSION_REQUIRED_FEE_CHANGE'
            ],
            // States for Pending Apprisal Orders dashboard - used for Dashboard page for originator and Appraisal
            pending: [
                'PENDING_APPRAISER_ACCEPTANCE',
                'PENDING_ASSIGNMENT',
                'ORDER_DRAFTED',
                'QUOTE_REQUIRED',
                'PENDING_QUOTE',
                'FINALIZING_QUOTE',
                'PENDING_PAYMENT',
                'PENDING_REPORT_COMPLETION',
                'ACCEPTANCE_PENDING_CLARIFICATION',
                'ACCEPTANCE_PENDING_DUE_DATE_CHANGE',
                'ACCEPTANCE_PENDING_FEE_CHANGE',
                'PENDING_SCHEDULE_VISIT_FEE_CHANGE',
                'PENDING_SCHEDULE_VISIT_CLARIFICATION',
                'PENDING_SCHEDULE_VISIT_DUE_DATE_CHANGE',
                'PENDING_SUBMISSION_CLARIFICATION',
                'PENDING_SUBMISSION_FEE_CHANGE',
                'PENDING_SUBMISSION_DUE_DATE_CHANGE',
                'PENDING_VISIT_CLARIFICATION',
                'PENDING_VISIT_FEE_CHANGE',
                'PENDING_VISIT_DUE_DATE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_CLARIFICATION',
                'PENDING_COSIGNER_ASSIGNMENT_FEE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_DUE_DATE_CHANGE',
                'PENDING_COSIGN_CLARIFICATION',
                'PENDING_COSIGN_FEE_CHANGE',
                'PENDING_COSIGN_DUE_DATE_CHANGE',
                'PENDING_RESUBMISSION_REQUIRED_CLARIFICATION',
                'PENDING_RESUBMISSION_REQUIRED_DUE_DATE_CHANGE',
                'PENDING_RESUBMISSION_REQUIRED_FEE_CHANGE'

            ],
            // States for Outstanding Apprisal Orders dashboard
            outstanding: [
                'PENDING_SCHEDULE_VISIT',
                'PENDING_VISIT',
                'PENDING_COSIGNER_ASSIGNMENT',
                'PENDING_COSIGN',
                'PENDING_SUBMISSION',
                'REPORT_SUBMITTED',
                'RESUBMISSION_REQUIRED',
                'REPORT_REJECTED',
                'REPORT_COMPLETE',
                'PENDING_SCHEDULE_VISIT_FEE_CHANGE',
                'PENDING_SCHEDULE_VISIT_CLARIFICATION',
                'PENDING_SCHEDULE_VISIT_DUE_DATE_CHANGE',
                'PENDING_SUBMISSION_CLARIFICATION',
                'PENDING_SUBMISSION_FEE_CHANGE',
                'PENDING_SUBMISSION_DUE_DATE_CHANGE',
                'PENDING_VISIT_CLARIFICATION',
                'PENDING_VISIT_FEE_CHANGE',
                'PENDING_VISIT_DUE_DATE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_CLARIFICATION',
                'PENDING_COSIGNER_ASSIGNMENT_FEE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_DUE_DATE_CHANGE',
                'PENDING_COSIGN_CLARIFICATION',
                'PENDING_COSIGN_FEE_CHANGE',
                'PENDING_COSIGN_DUE_DATE_CHANGE',
                'PENDING_RESUBMISSION_REQUIRED_CLARIFICATION',
                'PENDING_RESUBMISSION_REQUIRED_DUE_DATE_CHANGE',
                'PENDING_RESUBMISSION_REQUIRED_FEE_CHANGE'
            ],
            // States for Past Apprisal Orders dashboard
            past: [
                'REFUND_REQUIRED',
                'ORDER_CANCELLED',
                'ON_HOLD',
                'REPORT_ACCEPTED'
            ],
            // States in which the assigned appraiser is allowed to edit the active report
            editable: [
                'PENDING_VISIT',
                'PENDING_SCHEDULE_VISIT',
                'PENDING_COSIGNER_ASSIGNMENT',
                'PENDING_COSIGN',
                'PENDING_SUBMISSION',
                'RESUBMISSION_REQUIRED',
                'PENDING_REPORT_COMPLETION'
            ],
            // States in which the order is editable
            orderEditable: [
                'ORDER_DRAFTED',
                'QUOTE_REQUIRED',
                'PENDING_QUOTE',
                'PENDING_ASSIGNMENT',
                'PENDING_PAYMENT',
                'PENDING_APPRAISER_ACCEPTANCE',
                "PENDING_REPORT_COMPLETION",
                'ACCEPTANCE_PENDING_CLARIFICATION',
                'ACCEPTANCE_PENDING_DUE_DATE_CHANGE',
                'ACCEPTANCE_PENDING_FEE_CHANGE',
                'ADDITIONAL_PAYMENT_REQUIRED',
                'PENDING_SCHEDULE_VISIT',
                'PENDING_VISIT',
                'PENDING_COSIGNER_ASSIGNMENT',
                'PENDING_SCHEDULE_VISIT_CLARIFICATION',
                'PENDING_SCHEDULE_VISIT_FEE_CHANGE',
                'PENDING_SCHEDULE_VISIT_DUE_DATE_CHANGE',
                'PENDING_VISIT_CLARIFICATION',
                'PENDING_VISIT_FEE_CHANGE',
                'PENDING_VISIT_DUE_DATE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_CLARIFICATION',
                'PENDING_COSIGNER_ASSIGNMENT_FEE_CHANGE',
                'PENDING_COSIGNER_ASSIGNMENT_DUE_DATE_CHANGE',
                'PENDING_COSIGN_CLARIFICATION',
                'PENDING_COSIGN_FEE_CHANGE',
                'PENDING_COSIGN_DUE_DATE_CHANGE',
                'PENDING_COSIGN',
                'PENDING_SUBMISSION_CLARIFICATION',
                'PENDING_SUBMISSION_FEE_CHANGE',
                'PENDING_SUBMISSION_DUE_DATE_CHANGE',
                'PENDING_SUBMISSION',
                'RESUBMISSION_REQUIRED'
            ],
            // States the report may be in after the first submission
            submitted: [
                "REPORT_SUBMITTED",
                "RESUBMISSION_REQUIRED",
                "REPORT_COMPLETE",
                "REPORT_ACCEPTED",
                "REPORT_REJECTED",
                "ORDER_CANCELLED",
                "REFUND_REQUIRED"
            ],
            // States orders at risk
            atRisk: [
                'ORDER_DRAFTED',
                'PENDING_APPRAISER_ACCEPTANCE',
                'PENDING_ASSIGNMENT',
                'ACCEPTANCE_PENDING_CLARIFICATION',
                'ACCEPTANCE_PENDING_DUE_DATE_CHANGE',
                'ACCEPTANCE_PENDING_FEE_CHANGE',
                'PENDING_SCHEDULE_VISIT',
                'PENDING_VISIT',
                'MANUAL_INTERVENTION_REQUIRED'
            ],
            // All States (defaults to all states non-VC can see)
            all: [
                "ORDER_DRAFTED",
                "PENDING_PAYMENT",
                "PAYMENT_COMPLETE",
                "PENDING_ASSIGNMENT",
                "PENDING_APPRAISER_ACCEPTANCE",
                "PENDING_REPORT_COMPLETION",
                "ACCEPTANCE_PENDING_CLARIFICATION",
                "ACCEPTANCE_PENDING_DUE_DATE_CHANGE",
                "ACCEPTANCE_PENDING_FEE_CHANGE",
                "ADDITIONAL_PAYMENT_REQUIRED",
                "PENDING_SCHEDULE_VISIT",
                "PENDING_VISIT",
                "PENDING_COSIGNER_ASSIGNMENT",
                "PENDING_COSIGN",
                "PENDING_SUBMISSION",
                "ON_HOLD",
                "REPORT_SUBMITTED",
                "RESUBMISSION_REQUIRED",
                "REPORT_COMPLETE",
                "REPORT_ACCEPTED",
                "REPORT_REJECTED",
                "ORDER_CANCELLED",
                "REFUND_REQUIRED"
            ],
            // All states where report can be signed.
            canSign: [
                 "PENDING_COSIGNER_ASSIGNMENT",
                 "PENDING_COSIGN",
                 "PENDING_SUBMISSION",
                 "RESUBMISSION_REQUIRED"
            ]
        };

        // Initialize service using the current account
        checkRoles();
        $rootScope.$on('$destroy', $rootScope.$on('authenticationSuccess', checkRoles));

        return service;

        function checkRoles() {
            return Principal.identity().then(function(account) {
                // Do nothing if the account has not changed
                if(account === _account) return;
                _account = account;

                // Check if this user is an originator or appraiser before initializing
                return $q.all({
                    'isOriginator': Principal.matchAnyAuthority(/^ROLE_ORIGINATOR.*/),
                    'isAppraiser': Principal.matchAnyAuthority(/^ROLE_(APPRAISER|APPRAISAL).*/)
                }).then(init);
            });
        }

        function init(roles) {
            // TODO: Only return states that the current user should be allowed to see
            service.all = [
                "ORDER_DRAFTED",
                "PENDING_PAYMENT",
                "PAYMENT_COMPLETE",
                "PENDING_ASSIGNMENT",
                "PENDING_APPRAISER_ACCEPTANCE",
                "ACCEPTANCE_PENDING_CLARIFICATION",
                "ACCEPTANCE_PENDING_DUE_DATE_CHANGE",
                "ACCEPTANCE_PENDING_FEE_CHANGE",
                "ADDITIONAL_PAYMENT_REQUIRED",
                "PENDING_SCHEDULE_VISIT",
                "PENDING_VISIT",
                "PENDING_COSIGNER_ASSIGNMENT",
                "PENDING_COSIGN",
                "PENDING_SUBMISSION",
                "ON_HOLD",
                "REPORT_SUBMITTED",
                "RESUBMISSION_REQUIRED",
                "REPORT_COMPLETE",
                "REPORT_ACCEPTED",
                "REPORT_REJECTED",
                "ORDER_CANCELLED",
                "REFUND_REQUIRED"
            ];
        }

        /**
         * Check if the provided appraisal order is in the specified state group, or has the
         * specified state
         * @param  {Object} order The appraisal order
         * @param  {String} state The name of the state group, or a specific state to check
         * @return {Boolean}      True if there is a match, otherwise false.
         */
        function inState(order, state) {
            state = service[state];
            if(angular.isArray(state)) {
                return state.indexOf(order.state) !== -1;
            } else {
                return order.state === state;
            }
        }

        /**
         * Check if the provided appraisal order has any of the specified states.
         *
         * @return {Boolean} True if the appraisal order has any of the provided states, otherwise false
         */
        function inAnyState(order, states) {
            return states.indexOf(order.state) !== -1;
        }
    }
})();
