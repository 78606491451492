(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('AppraisalReport', AppraisalReport);

    AppraisalReport.$inject = ['$resource', 'DateUtils'];

    function AppraisalReport ($resource, DateUtils) {
        var resourceUrl =  'api/appraisal-reports/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryHistory': { method: 'GET', url: 'api/appraisal-reports/history', isArray: true },
            'queryHistoryExport': {
                method: 'GET',
                url: 'api/appraisal-reports/history/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.appraisalDueDate = DateUtils.convertDateTimeFromServer(data.appraisalDueDate);
                    }
                    return data;
                }
            },
            'getMinimal': {method: 'GET', url: 'api/appraisal-reports/:id/minimal'},
            'getByAccessKey': {
                method: 'GET', url: 'api/appraisal-reports/by-access-key/:accessKey',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.appraisalDueDate = DateUtils.convertDateTimeFromServer(data.appraisalDueDate);
                    }
                    return data;
                }
            },
            'getFormDefinitions': { method: 'GET', url: 'api/appraisal-reports/:id/form-definitions', isArray: true },
            'getEligibleForms': { method: 'GET', url: 'api/appraisal-reports/:id/eligible-forms', isArray: true },
            'update': { method:'PUT' },
            'cloneForms': {
                method: 'POST',
                url: 'api/appraisal-reports/:targetId/cloneForms/:sourceId'
            },
            'generatePdf': { method:'GET', url: 'api/appraisal-reports/generate-pdf/:id',
                responseType:'arraybuffer',
                transformResponse: function(data, headersGetter) { return { data : data }; }
            },
            'generatePdfByAccessKey': { method:'GET', url: 'api/appraisal-reports/generate-pdf/by-access-key/:id',
                responseType:'arraybuffer',
                transformResponse: function(data, headersGetter) { return { data : data }; }
            },
            'generateHtml': { method: 'POST', isArray: false, url: 'api/appraisal-reports/generate-html/:id'},
            'generateHtmlByAccessKey': { method: 'POST', isArray: false, url: 'api/appraisal-reports/generate-html/by-access-key/:accessKey'},
            'generateICal': { method:'POST', url: 'api/appraisal-reports/generate-ical/:id',
                responseType:'arraybuffer',
                transformResponse: function(data, headersGetter) { return { data : data }; }
            },
            'scheduleVisit': { method:'POST', url: 'api/appraisal-reports/schedule-visit'},
            'completeVisit': { method: 'POST', url: 'api/appraisal-reports/complete-visit' },
            'submitReport': { method: 'PUT', url: 'api/appraisal-reports/:id/submit-report' },
            'assignCosigner': { method: 'POST', url: 'api/appraisal-reports/:id/assign-cosigner/:appraiserUserId' },
            'signReport': { method: 'PUT', url: 'api/appraisal-reports/:id/sign-report' },
            'acceptReport': { method: 'PUT', url: 'api/appraisal-reports/:id/accept-report' },
            'declineReport': { method: 'PUT', url: 'api/appraisal-reports/decline-report' },
            'validateForms': { method: 'GET', url: 'api/appraisal-reports/:id/validate-forms' },
            'validateFields': { method: 'GET', url: 'api/appraisal-reports/:id/validate-fields' },
            'sendReportBack': { method: 'POST', url: 'api/appraisal-reports/send-report-back' },
            'getMinimalGrid': { method: 'GET', url:'api/appraisal-reports-minimal', isArray: true },
            'searchMinimalGrid': { method: 'GET', url:'api/_search/appraisal-reports-minimal', isArray: true},
            'regenerateReport': { method: 'POST', url:'api/appraisal-reports/:id/regenerate'}
        });
    }
})();
